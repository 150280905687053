<template>
  <v-container class="selector px-0">
    <v-row>
      <v-col cols="6" class="">
        <h2 class="title_header">
          {{ $t('credentialDesign.title') }}
        </h2>
      </v-col>
      <v-col cols="12" class="">
        <v-card elevation="0">
          <v-container class="text-center">
            <v-row>
              <v-col cols="6" class="py-0">
                <v-img
                  class="mx-auto mb-9"
                  width="250"
                  contain
                  :src="require('@/assets/credential-design/choose-preset.png')"
                />
                <h3 class="subtitle_text_strong mb-5">
                  {{ $t('credentialDesign.subtitle[0]') }}
                </h3>
                <v-btn class="advance-btn" text @click="choosePresetDesign()">
                  <!-- <IconAdd /> -->
                  {{ $t('credentialDesign.buttons[0]') }}</v-btn
                >
              </v-col>
              <v-col cols="6" class="py-0">
                <v-img
                  class="mx-auto mb-9"
                  width="250"
                  contain
                  :src="
                    require('@/assets/credential-design/create-your-design.png')
                  "
                />
                <h3 class="subtitle_text_strong mb-5">
                  {{ $t('credentialDesign.subtitle[1]') }}
                </h3>
                <v-btn class="advance-btn" text @click="createDesign()">
                  <!-- <IconAdd /> -->
                  {{ $t('credentialDesign.buttons[1]') }}</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  methods: {
    choosePresetDesign() {
      this.$router.push({
        name: `ChoosePresetTemplate`,
      });
    },
    createDesign() {
      this.$store.dispatch('resetTemplate');

      this.$router.push({
        name: `CreateTemplateView`,
      });
    },
  },
};
</script>

<style lang="scss">
.credential-design {
  .selector {
    .v-card {
      padding-top: 52px;
      padding-bottom: 52px;
      .advance-btn.v-btn--disabled {
        background-color: gray !important;
      }
      .normal_text {
        max-width: 330px;
        margin: 0 auto;
        margin-bottom: 30px;
      }
      .col:first-child {
        border-right: solid 1px #eeeeee;
      }
      .col:last-child {
        border-left: solid 1px #eeeeee;
      }
    }
  }
}
</style>
